import React, { useEffect, useState, useContext } from 'react'
import { Button, Container, Row, Col, Navbar, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames';
import '../../resources/css/index.css'
import StoreContext from '../store';
const Header = () => {
    const [currentSelet, setCurrentSelect] = useState<string>('home')
    const store: any = useContext(StoreContext)
    const { t } = useTranslation();
    useEffect(() => {
        window.location.hash = '#home'
    }, [])

    useEffect(() => {
        document.title = store?.company
    })
    return (
        <Container fluid={true}>
            <Navbar bg="white" variant="light" fixed='top' expand='lg' className='headButtom'>
                <Container>
                    <Navbar.Brand >
                        <img src={store?.logoPrc} width="32" height="32" className="d-inline-block align-top" />
                    </Navbar.Brand>
                    <Navbar.Brand href="#home">{store?.company}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="me-auto" >
                            <Nav.Link href="#home" className={classnames("mx-5", currentSelet == 'home' ? 'active' : null)}
                                onClick={() => { setCurrentSelect('home') }}>{t('首页')}</Nav.Link>
                            <Nav.Link href="#step" className={classnames("mx-5", currentSelet == 'step' ? 'active' : null)}
                                onClick={() => { setCurrentSelect('installment') }}> {t('贷款分期')}</Nav.Link>
                            <Nav.Link href="#question" className={classnames("mx-5", currentSelet == 'question' ? 'active' : null)}
                                onClick={() => { setCurrentSelect('question') }}> {t('常见问题')}</Nav.Link>
                            <Nav.Link href="#connect" className={classnames("mx-5", currentSelet == 'connect' ? 'active' : null)}
                                onClick={() => { setCurrentSelect('connect') }}>  {t('联系我们')}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Container>
    )
}
export default Header