
import classnames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import mxBanner from '../../resources/images/mxBanner.png'
import advantage1 from '../../resources/images/advantage1.png'
import advantage2 from '../../resources/images/advantage2.png'
import advantage3 from '../../resources/images/advantage3.png'
import advantage4 from '../../resources/images/advantage4.png'
import stpeBG from '../../resources/images/mx-step-bg.png'
import step1 from '../../resources/images/mx-step1.png'
import step2 from '../../resources/images/mx-step2.png'
import step3 from '../../resources/images/mx-step3.png'
import pb1 from '../../resources/images/problem1.png'
import pb2 from '../../resources/images/problem2.png'
import pb3 from '../../resources/images/problem3.png'
import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const { t } = useTranslation()
    const { language } = getI18n()
    const isTHLanguage = language == 'th'
    const isMXLanguage = language == 'mx'
    const store: any = useContext(StoreContext)
    return <>
        <Container fluid={true} className='p-t-58 px-0'>
            <div id="home">
                <img src={mxBanner} width="100%" />
                <div className='mx-banner-image-contail'>
                    <p className='service-title mx-title-color pt-0 mb-0 ft-40'>
                        {t('安全')}·{t('放心')}·{t('保障')}
                    </p>
                    <p className={classnames('mx-title-1 pt-0 ft40 ft-40')}>
                        {t('用钱就找')}{store?.company}
                    </p>
                    <p className="mx-banner-button">
                        <span> {t('快速审批 灵活借还')}</span>
                    </p>
                </div>
            </div>
            <div className='mt-82 d-flex justify-content-center flex-column align-items-center '>
                <span className="mx-center-Warp-title">
                    {t('我们的优势')}
                </span>
                <hr style={{ width: '83px', height: '3px', color: '#4051A1', opacity: 1, marginTop: 30 }}></hr>
                <div className="d-flex mt-82 mb-82 justify-content-around advantage-warp ">
                    <div className='advantage-item  d-flex flex-column align-items-center ' >
                        <img src={advantage1}></img>
                        <hr />
                        <p className='advantage-item-title'>{t('额度高')}</p>
                        <p className='advantage-item-title-d'>{t('最高可借额度')}20000</p>
                    </div>
                    <div className='advantage-item  d-flex flex-column align-items-center ' >
                        <img src={advantage2}></img>
                        <hr />
                        <p className='advantage-item-title'>{t('速度快')}</p>
                        <p className='advantage-item-title-d'>{t('30秒审批，3分钟到账')}</p>
                    </div>
                    <div className='advantage-item  d-flex flex-column align-items-center ' >
                        <img src={advantage3}></img>
                        <hr />
                        <p className='advantage-item-title'>{t('操作简单')}</p>
                        <p className='advantage-item-title-d'>{t('手机即可申请，还款灵活操作')}</p>
                    </div>
                    <div className='advantage-item  d-flex flex-column align-items-center ' >
                        <img src={advantage4}></img>
                        <hr />
                        <p className='advantage-item-title'>{t('低利息')}</p>
                        <p className='advantage-item-title-d'>{t('0抵押0担保额度有保障')}</p>

                    </div>
                </div>
            </div>
            <div id="step" style={{ width: '100%', backgroundImage: `url(${stpeBG})`, height: '1069px' }} className="d-flex justify-content-center">
                <div className="d-flex  mx-step-warp flex-column align-items-center" >
                    <span className="mx-step-title-Warp mt-82">
                        {t('申请流程')}
                    </span>
                    <hr className="step-hr" />

                    <div className="d-flex flex-column mx-step-context">
                        <div className='d-flex justify-content-start ' >
                            <img src={step1} ></img>
                            <div className="image-step-contail">
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <span className="step-num">01</span>
                                        <span className="step-num-t">{t('打开App')}</span>
                                    </div>
                                    <span className="step-num-s">
                                        {t('APP首页点击“我要申请”')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end '>
                            <div className={classnames(isTHLanguage ? 'image-step-right-contail' : isMXLanguage ? 'image-step-right-contaild' : 'image-step-right-contails')}>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <span className="step-num-t" style={{ marginRight: '10px' }}>{t('获得额度')}</span>
                                        <span className="step-num">02</span>
                                    </div>
                                    <span className="step-num-right-s">
                                        {t('填写信息获取额度点击“我要贷款”')}
                                    </span>
                                </div>
                            </div>
                            <img src={step2}></img>
                        </div>
                        <div className='d-flex justify-content-start '>
                            <img src={step3}></img>
                            <div className="image-step-contail">
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <span className="step-num">03</span>
                                        <span className="step-num-t">{t('实时到账')}</span>
                                    </div>
                                    <span className="step-num-s">
                                        {t('恭喜！借款成功 到账至银行卡')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="question" className="w-100 mt-82 d-flex justify-content-center flex-column align-items-center ">
                <span className="mx-center-Warp-title">
                    {t('常见问题')}
                </span>
                <hr style={{ width: '83px', height: '3px', color: '#4051A1', opacity: 1, marginTop: 30 }}></hr>
                <div className='w-110 '>
                    <div className="mt-82  d-flex justify-content-center  align-items-center  ">
                        <div style={{ width: '238px', backgroundImage: `url(${pb1})`, height: '162px' }}></div>
                        <div className="pb-item">
                            <p className="mx-pb-title">{t('申请的门槛高吗？')}</p>
                            <p className="mx-pb-d">{t('提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请')}</p>
                        </div>
                    </div>
                    <div className="mt38  d-flex justify-content-center  align-items-center">
                        <div style={{ width: '238px', backgroundImage: `url(${pb2})`, height: '162px' }}></div>
                        <div className="pb-item">
                            <p className="mx-pb-title">{t('借款后可以多久到账？')}</p>
                            <p className="mx-pb-d">{t('通过审核后最快三分钟到账')}</p>
                        </div>
                    </div>
                    <div className="mt38  d-flex justify-content-center  align-items-center">
                        <div style={{ width: '238px', backgroundImage: `url(${pb3})`, height: '162px' }}></div>
                        <div className="pb-item">
                            <p className="mx-pb-title">{t('什么时候可以还款?')}</p>
                            <p className="mx-pb-d">{t('可以进行提前还款')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </>
}
export default BodyContext