export default {
    "安全": 'Safety',
    "放心": "Rest",
    "保障": "Assure",
    "用钱就找": "Use money to find",
    '快速审批 灵活借还': "Fast approval, flexible loan and return",
    "首页": "Home",
    "贷款分期": "Installment",
    "常见问题": "Common Problem",
    "联系我们": "Contact US",
    "我们的优势": "Our Advantage",
    "额度高": "High limit",
    "速度快": "High speed",
    "操作简单": "Easy to use",
    "低利息": "Low interest",
    "最高可借额度": "Maximum loan amount",
    "30秒审批，3分钟到账": "30 seconds for approval, 3 minutes to arrive",
    "手机即可申请，还款灵活操作": "Mobile phone can apply, repayment flexible operation",
    "0抵押0担保额度有保障": "0 mortgage 0 guarantee limit is guaranteed",
    "申请流程": "Application Process",
    "关于我们": "About US",
    "隐私政策": "Privacy Policy",
    "电话": "Phone",
    "邮箱": "Email",
    "地址": "Address",
    "官方Facebook": "Official Facebook",
    "官方Line": "Official Line",
    "立即申请": "Apply Immediately",
    "借钱来这!": "Borrow Money Come Here!",
    "最高可贷20000申请便捷": "Up to 20000 loan, easy to apply",
    "最高可贷20000快速审批": "Fast approval for a loan of up to 20,000",
    "最高可贷20000极速审批": "Fast approval for loan up to 20,000",
    "医美": "Medical Cosmetology",
    "口腔": "Oral cavity",
    "个人消费": "Personal consumption",
    "信用生活": "Credit Life",
    "问题": "Common Problem",
    "金融信贷服务": "Financial Credit Service",
    "品牌强": "Strong brand",
    "权威品牌，安全透明": "Authoritative brand, safe and transparent",
    "借款步骤": "Application steps",
    "打开App": "Open the app",
    "APP首页点击“我要申请”": `Click "I want to apply" on the homepage of the APP`,
    "获得额度": "Get quota",
    "填写信息获取额度点击“我要贷款”": `Fill in the information to obtain the quota and click "I want to loan"`,
    '实时到账': 'Real-time arrival',
    '恭喜！借款成功 到账至银行卡': "Congratulations! The loan is successfully credited to the bank card",
    "申请的门槛高吗？": "Is the threshold for application high?",
    '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请': 'Provide your ID card and bank card. Registered users over 18 years old can apply.',
    '借款后可以多久到账？': 'How long does it take to arrive after borrowing?',
    '通过审核后最快三分钟到账': 'The account will arrive in the fastest three minutes after passing the audit and be issued to the bank card. However, due to network reasons, the arrival time may be delayed. Please notify the receiving bank card for the specific arrival time Prevail',
    '什么时候可以还款?': 'When can I repay',
    '可以进行提前还款': 'You can make early repayment. Borrow today and pay tomorrow. Repayment only calculates the interest up to the day',
    '查看更多问题': 'More questions',
    '客服': 'customer service',
    '如需联系我们请拨打客服热线': 'If you need to contact us, please call the customer service hotline',
    '时间': 'Service hours: 9:00 to 21:00 daily',
}