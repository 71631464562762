
import classnames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import enBanner from '../../resources/images/enBanner.png'
import ad1 from '../../resources/images/ad1.png'
import ad2 from '../../resources/images/ad2.png'
import ad3 from '../../resources/images/ad3.png'
import ad4 from '../../resources/images/ad4.png'
import lifeLeft from '../../resources/images/life-left.png'
import lifeRight from '../../resources/images/life-right.png'
import stepLeft from '../../resources/images/step-left.png'
import stepRight from '../../resources/images/step-right.png'
import life1 from '../../resources/images/life1.png'
import life2 from '../../resources/images/life2.png'
import life3 from '../../resources/images/life3.png'
import step1 from '../../resources/images/en-step1.png'
import step2 from '../../resources/images/en-step2.png'
import step3 from '../../resources/images/en-step3.png'
import enIcon from '../../resources/images/en-icon.png'
import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const { t } = useTranslation()
    const { language } = getI18n()
    const store: any = useContext(StoreContext)
    return <>
        <Container fluid={true} className='p-t-58 px-0'>
            <div id="home">
                <img src={enBanner} width="100%" />
                <div className='en-banner-image-contail title-color'>
                    <p className='service-title pt-0 mb-0 ft-70'>
                        {t('借钱来这!')}
                    </p>
                    <p className={classnames('mx-title-1 pt-0 ft40 ft-70 title-color')}>
                        {t('用钱就找')}{store?.company}
                    </p>
                    <p className="en-banner-button en-button"  >
                        <span> {t('立即申请')}</span>
                    </p>
                </div>
            </div>
            <div className="en-advantage d-flex justify-content-center ">
                <div className="warp d-flex justify-content-around align-items-center">
                    <div className="d-flex  align-items-center">
                        <div >
                            <img src={ad1} width="75px" />
                        </div>
                        <div>
                            <span className="advantage-t">{t("额度高")}</span>
                            <span className="advantage-d">{t("最高可借额度")} 20000</span>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center">
                        <div style={{ width: '50%', textAlign: "center" }}>
                            <img src={ad2} width="75px" />
                        </div>
                        <div>
                            <span className="advantage-t">{t("速度快")}</span>
                            <span className="advantage-d">{t("30秒审批，3分钟到账")}</span>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center">
                        <div style={{ width: '50%', textAlign: "center" }}>
                            <img src={ad3} width="75px" />
                        </div>
                        <div>
                            <span className="advantage-t">{t("操作简单")}</span>
                            <span className="advantage-d">{t("手机即可申请，还款灵活操作")}</span>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center">
                        <div style={{ width: '50%', textAlign: "center" }}>
                            <img src={ad4} width="75px" height='75px' />
                        </div>
                        <div>
                            <span className="advantage-t">{t("低利息")}</span>
                            <span className="advantage-d">{t("0抵押0担保额度有保障")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="en-life d-flex justify-content-center">
                <div className="life-warp d-flex justify-content-center flex-column  align-items-center">
                    <div className="d-flex justify-content-center align-items-center" >
                        <div style={{ backgroundImage: `url(${lifeLeft})` }} className="en-life-s"> </div>
                        <label style={{ padding: '0px 20px' }}>{t('信用生活')}</label>
                        <div style={{ backgroundImage: `url(${lifeRight})` }} className="en-life-s"> </div>
                    </div>

                    <div className="d-flex justify-content-around w-100 lift-ct">
                        <div className='item'>
                            <img src={life1} width={'100%'}></img>
                            <div className="life-d-warp  w-100">
                                <p className='advantage-t'>{t('个人消费')}</p>
                                <p className='advantage-d'>{t('最高可贷20000申请便捷')}</p>
                            </div>
                        </div>
                        <div className='item'>
                            <img src={life2} width={'100%'}></img>
                            <div className="life-d-warp  w-100">
                                <p className='advantage-t'>{t('医美')}</p>
                                <p className='advantage-d'>{t('最高可贷20000快速审批')}</p>
                            </div>
                        </div>
                        <div className='item'>
                            <img src={life3} width={'100%'}></img>
                            <div className="life-d-warp  w-100">
                                <p className='advantage-t'>{t('口腔')}</p>
                                <p className='advantage-d'>{t('最高可贷20000极速审批')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='step' className="life-warp d-flex flex-column en-step align-items-center">
                <div className="d-flex justify-content-center align-items-center " >
                    <div style={{ backgroundImage: `url(${stepLeft})` }} className="en-life-s"> </div>
                    <label style={{ padding: '0px 20px' }} className="title-color">{t('申请流程')}</label>
                    <div style={{ backgroundImage: `url(${stepRight})` }} className="en-life-s"> </div>
                </div>
                <div className="d-flex justify-content-around lift-ct en-step-warp">
                    <div className="step-item d-flex flex-column align-items-center ">
                        <div style={{ backgroundImage: `url(${step1})`, height: '195px', width: '195px', }} > </div>
                        <div className='w-100 text-center mt-5 '>
                            <p className="advantage-t  ft-34 title-color">{t("打开App")}</p>
                            <p className="advantage-d ft-26 title-color">{t('APP首页点击“我要申请”')}</p>
                        </div>
                    </div>
                    <div className="step-item d-flex flex-column align-items-center">
                        <div style={{ backgroundImage: `url(${step2})`, height: '195px', width: '195px', }} > </div>
                        <div className='w-100 text-center mt-5'>
                            <p className="advantage-t ft-34 title-color">{t('获得额度')}</p>
                            <p className="advantage-d ft-26  title-color">{t('填写信息获取额度点击“我要贷款”')}</p>
                        </div>
                    </div>
                    <div className="step-item d-flex flex-column align-items-center">
                        <div style={{ backgroundImage: `url(${step3})`, height: '195px', width: '195px', }} > </div>
                        <div className='w-100 text-center mt-5  '>
                            <p className="advantage-t ft-34 title-color" >{t('实时到账')}</p>
                            <p className="advantage-d  ft-26 title-color">{t('恭喜！借款成功 到账至银行卡')}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id='question' className="d-flex flex-column align-items-center w-100">
                <div className="d-flex pb-warp justify-content-center align-items-center " >
                    <div style={{ backgroundImage: `url(${lifeLeft})` }} className="en-life-s"> </div>
                    <label style={{ padding: '0px 20px' }} >{t('常见问题')}</label>
                    <div style={{ backgroundImage: `url(${lifeRight})` }} className="en-life-s"> </div>
                </div>
                <div className="d-flex pb-warp flex-column">
                    <div className="d-flex ">
                        <div className="d-flex flex-column align-items-end">
                            <div className="en-step-num">Part1</div>
                            <div style={{ backgroundImage: `url(${enIcon})` }} className="en-icon"></div>
                        </div>
                        <div style={{ paddingTop: '5px', marginLeft: '20px' }} >
                            <p className="en-setp-d"> {t('申请的门槛高吗？')}</p>
                            <p className="en-setp-t"> {t('提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请')}</p>
                        </div>
                    </div>
                    <div className="d-flex mt-5">
                        <div className="d-flex flex-column align-items-end">
                            <div className="en-step-num">Part2</div>
                            <div style={{ backgroundImage: `url(${enIcon})` }} className="en-icon"></div>
                        </div>
                        <div style={{ paddingTop: '5px', marginLeft: '20px' }} >
                            <p className="en-setp-d"> {t('借款后可以多久到账？')}</p>
                            <p className="en-setp-t">{t('通过审核后最快三分钟到账')}</p>
                        </div>
                    </div>

                    <div className="d-flex mt-5">
                        <div className="d-flex flex-column align-items-end">
                            <div className="en-step-num">Part3</div>
                            <div style={{ backgroundImage: `url(${enIcon})` }} className="en-icon"></div>
                        </div>
                        <div style={{ paddingTop: '5px', marginLeft: '20px' }} >
                            <p className="en-setp-d"> {t('什么时候可以还款?')}</p>
                            <p className="en-setp-t"> {t('可以进行提前还款')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </>
}
export default BodyContext