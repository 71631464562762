
import Body from './body'
import BottomContext from './bottom'
const TempleteThree = () => {
    return <>
        <Body></Body>
        <BottomContext></BottomContext>
    </>
}

export default TempleteThree