export default {
    '首页': 'Página de inicio',
    '贷款分期': 'Plazas de péstamos ',
    '常见问题': 'Preguntas comunes',
    '联系我们': 'Contacto',
    '金融信贷服务': 'Servicios financieros de crédito',
    '用钱就找': 'Si necesitas dinero ',
    '速度快': "Velocidad rápida",
    '额度高': "Cuotas elevadas",
    '操作简单': "Operación sencilla",
    '品牌强': "Marcas excellentes",
    '最高可借额度': 'Cuetos de préstamos máximos',
    '30秒审批，3分钟到账': 'identicifación en 30 segundos y 3 minutos para la cuenta',
    '手机即可申请，还款灵活操作': 'Aplicación móvil y reembolso flexible',
    '权威品牌，安全透明': 'Marca autorizada, segura ',
    '借款步骤': 'Pasos del préstamo',
    '打开App': 'abierto la aplicación',
    '获得额度': 'obtener los cuetos',
    '实时到账': 'llegada al tiempo',
    'APP首页点击“我要申请”': 'Haga clic en "Quiero solicitar" en la página de inicio de la APP',
    '填写信息获取额度点击“我要贷款”': 'Rellene sus datos para obtener una línea de crédito y haga clic en "Quiero un préstamo"',
    '恭喜！借款成功 到账至银行卡': 'Enhorabuena. El préstamo ha sido un éxito',
    '申请的门槛高吗？': '¿ Umbral de aplicación es alto?',
    '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请': 'Proporcione el tuyo propio  carné de identidad y su tarjeta bancaria. Los usuarios registrados mayores de 18 años pueden solicitarlo',
    '借款后可以多久到账？': '¿Cuánto tiempo puedo pedir prestado y cuánto tarda en llegar a una cuenta?',
    '通过审核后最快三分钟到账': 'La hora de pago más temprana es tres minutos después de la verificación y la emisión a la tarjeta bancaria, pero debido a razones de la red, la hora de llegada puede retrasarse, por favor consulte la notificación de la tarjeta bancaria receptora para la hora de llegada específica.',
    '什么时候可以还款?': '¿Cuándo puedes devolver el préstamo?',
    '可以进行提前还款': 'El reembolso anticipado es posible, puede pedir un préstamo hoy y reembolsarlo mañana, y el reembolso sólo se calculará con los intereses hasta esa fecha',
    '时间': '(Horario de servicio: de 9:00 a 21:00 horas todos los días)',
    '如需联系我们请拨打客服热线': 'Si necesita ponerse en contacto con nosotros, llame a nuestra línea de atención al cliente',
    '客服': 'Servicio de atención al cliente',
    '下载App': 'Descargue la aplicación',
    '官方Facebook': 'Facebook oficial',
    'Ios客户端': 'IOS',
    '安卓客户端': 'Android',
    '推荐与服务': 'Recomendaciones y servicios',
    '服务号': 'Número de servicio',
    '官方邮箱': 'Correo electrónico oficial',
    '地址': 'Dirección',
    '关于我们': 'Sobre nosotros',
    '官方Line': 'Línea oficial',
    '查看更多问题': 'Ves más preguntas',
    "立即申请": "Solicite ahora",
    "信用生活": "Vida crediticia",
    "个人消费": "Consumo personal",
    "借钱来这!": "¡Pide dinero prestado aquí!",
    "安全": "Seguridad",
    "放心": "Tranquilidad",
    "保障": "Protección",
    "快速审批 灵活借还": " Aprobación rápida Préstamo y reembolso flexibles",
    "我们的优势": "Nuestros puntos fuertes",
    "低利息": "Interés bajo",
    "0抵押0担保额度有保障": "0 garantías y 0 avales con línea de crédito garantizada",
    "申请流程": "Proceso de solicitud",
    "官方": "Autoridades",
    "隐私政策": 'Política de privacidad',
    "电话": "Teléfono",
    "邮箱": "Correo electrónico ",
    "最高可贷20000申请便捷": "Facilidad para solicitar préstamos de hasta 20.000",
    "最高可贷20000快速审批": "Aprobación rápida de hasta 20.000 préstamos",
    "最高可贷20000极速审批": "Hasta 20.000 préstamos para una aprobación extremadamente rápida",
    "口腔": 'Cavidad bucal',
    "医美": "Belleza médica"
}