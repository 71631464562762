
import BodyContext from './body'
import BottomContext from './bottom'
const TempleteOne = () => {
    return <>
        <BodyContext></BodyContext>
        <BottomContext></BottomContext>
    </>
}

export default TempleteOne