
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import th from './th/th'
import zh from './zh/zh'
import mx from './mx/mx'
import en from './en/en'
import axios from "axios"
const resources = {
    th: {
        translation: th
    },
    zh: {
        translation: zh
    },
    mx: {
        translation: mx
    },
    en: {
        translation: en
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: `mx`,
        fallbackLng: `mx`,
        interpolation: {
            escapeValue: false,
        }
    });


export default i18n;