import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BottomContext = () => {
    const { t } = useTranslation()
    const store: any = useContext(StoreContext)
    return (
        <>
            <div id='connect' className='mx-bottom d-flex justify-content-center'>
                <div className='mx-buttom-warp d-flex'>
                    <div className='d-flex'>
                        <div>
                            <p className='ft-22'> {t('关于我们')}</p>
                            <div className='d-flex mt-2' >
                                <div className='ft-16'>{t('官方Facebook')}:</div>
                                <div className='bottom-item-value'>{store?.officialFaceBook}</div>
                            </div>
                            <div className='d-flex mt-2'>
                                <div className='ft-16'>{t('官方Line')}:</div>
                                <div className='bottom-item-value'>{store?.officialLine}</div>
                            </div>
                            <u className='mt-2' style={{ cursor: "pointer", display: "block" }} onClick={() => { window.open('/help/privacy_agreement.html') }}>{t("隐私政策")}</u>
                        </div>
                        <div>
                            <p className='ft-22'>{t('联系我们')}</p>
                            <div className='d-flex mt-2' >
                                <div className='ft-16'>{t('电话')}:</div>
                                <div className='bottom-item-value'>{store?.phone}</div>
                            </div>
                            <div className='d-flex mt-2' >
                                <div className='ft-16'>{t('邮箱')}:</div>
                                <div className='bottom-item-value'>{store?.email}</div>
                            </div>
                            <div className='d-flex mt-2' >
                                <div className='ft-16'>{t('地址')}:</div>
                                <div className='bottom-item-value'>{store?.address}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BottomContext