import Header from './head/index'
import React, { createContext, useEffect, useState } from "react"
import { TempleteType } from '../common/enum'
import TempleteOne from './TempleteOne/index'
import TempleteTwo from './TempleteTwo/index'
import TempleteThree from './TempleteThree/index'
import axios from 'axios'
import StoreContext from './store'
import { useTranslation, } from 'react-i18next'
const App = () => {
  let windowData: any = window
  const [store, setStore] = useState({});
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setStore(windowData.config)
    i18n.changeLanguage(windowData.config.language)
  }, [])

  const formatTemplete = () => {
    let stores: any = store;
    let templeteId = stores?.templeteId;
    switch (Number(templeteId)) {
      case TempleteType.templeteOne: {
        return <TempleteOne />
      }
      case TempleteType.templeteTwo: {
        return <TempleteTwo />
      }
      case TempleteType.templeteThree: {
        return <TempleteThree />
      }
    }
  }
  return (
    <StoreContext.Provider value={store} >
      <Header />
      {formatTemplete()}
    </StoreContext.Provider >

  );
}

export default App;
