export default {
    '首页': 'หน้าแรก',
    '贷款分期': 'งวดเงินกู้',
    '常见问题': 'ปัญหาที่พบบ่อย',
    '联系我们': 'ติดต่อเรา',
    '金融信贷服务': 'บริการสินเชื่อทางการเงิน',
    '用钱就找': 'หาเงินที่นี่',
    '速度快': "ความเร็วสูง",
    '额度高': "วงเงินกู้สูง",
    '操作简单': "ง่ายต่อการใช้",
    '品牌强': "แบรนด์นี้มาแรง",
    '最高可借额度': 'วงเงินกู้สูงสุด',
    '30秒审批，3分钟到账': 'อนุมัติ 30 วินาที，เงินเข้าภายใน 3 นาที',
    '手机即可申请，还款灵活操作': 'สมัครผ่านมือถือได้ และดำเนินการชำระคืนได้อย่างคล่องตัว',
    '权威品牌，安全透明': 'แบรนด์เผด็จการ ปลอดภัย',
    '借款步骤': 'ขั้นตอนการยืม',
    '打开App': 'เปิดApp',
    '获得额度': 'รับจำนวนเงินกู้',
    '实时到账': 'เงินเข้าทันที',
    'APP首页点击“我要申请”': 'คลิก "ฉันต้องการสมัคร" บนหน้าแรกของ" APP',
    '填写信息获取额度点击“我要贷款”': 'ระบุข้อมูลเพื่อรับจำนวนเงินแล้วคลิก "ฉันต้องการเงินกู้"',
    '恭喜！借款成功 到账至银行卡': 'ยินดีด้วย เงินกู้ถูกโอนเข้าบัตรธนาคารเรียบร้อยแล้ว',
    '申请的门槛高吗？': 'เกณฑ์การสมัครสูงไหม?',
    '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请': 'ระบุบัตรประจำตัวประชาชนของคุณ ผู้ใช้ที่ลงทะเบียนอายุมากกว่า 18 ปีสามารถสมัครได้',
    '借款后可以多久到账？': 'ได้รับเงินตอนไหนหลังจากยืมเงิน?',
    '通过审核后最快三分钟到账': 'หลังจากผ่านการตรวจสอบแล้ว  ได้รับเงิน3นาทีและออกให้กับบัตรธนาคาร บางครั้งอาจล่าช้า โปรดดูที่การแจ้งเตือนบัตรธนาคารที่ได้รับสำหรับเวลาที่มาถึงโดยเฉพาะ',
    '什么时候可以还款?': 'สามารถชำระคืนได้เมื่อไร?',
    '可以进行提前还款': 'ผ่อนชำระก่อนกำหนดได้ ยืมวันนี้จ่ายคืนพรุ่งนี้ ผ่อนคิดดอกเบี้ยถึงวันเท่านั้น',
    '时间': '(เวลาให้บริการ: 9:00 น. ถึง 21:00 น. ทุกวัน)',
    '如需联系我们请拨打客服热线': 'หากคุณต้องการติดต่อเรา กรุณาโทรติดต่อสายด่วนบริการลูกค้า',
    '客服': 'บริการลูกค้า',
    '下载App': 'ดาวน์โหลดAPP',
    '官方Facebook': 'Facebookทางการ',
    'Ios客户端': 'ฝ่ายบริการลูกค้า IOS',
    '安卓客户端': 'ฝ่ายบริการลูกค้า Android',
    '推荐与服务': 'คำแนะนำและบริการ',
    '服务号': 'หมายเลขบริการ',
    '官方邮箱': 'กล่องจดหมายอย่างเป็นทางการ',
    '地址': 'ที่อยู่',
    '关于我们': 'เกี่ยวกับเรา',
    '官方Line': 'ทางการline',
    '查看更多问题': 'ดูคำถามเพิ่มเติม',
    "安全": "ปลอดภัย",
    '放心': 'มั่นใจ',
    '保障': 'มั่นคง',
    "快速审批 灵活借还": "อนุมัติไว ยืมคืนที่ยืดหยุ่น",
    "我们的优势": "ข้อได้เปรียบของเรา",
    "低利息": "ดอกเบี้ยต่ำ",
    "0抵押0担保额度有保障": "0 จำนอง 0 วงเงินค้ำรับประกัน",
    "申请流程": "ขั้นตอนการสมัคร",
    "官方": "ทางการ",
    "隐私政策": 'นโยบายความเป็นส่วนตัว',
    "电话": "โทรศัพท์",
    "邮箱": "อีเมล",
    "借钱来这!": 'ยืมเงินมาที่นี่ !',
    "立即申请": "สมัครทันที",
    "信用生活": "เครดิตชีวิต",
    "个人消费": "การบริโภคส่วนบุคคล",
    "最高可贷20000申请便捷": "สินเชื่อสูงถึง 20,000 สมัครง่าย",
    "最高可贷20000快速审批": "อนุมัติเร็ว สินเชื่อสูงถึง 20,000",
    "最高可贷20000极速审批": "อนุมัติเร็ว สินเชื่อสูงถึง 20,000",
    "医美": "ความงามทางการแพทย์",
    "口腔": 'ช่องปาก'
}