export default {
    '首页': '首页',
    '贷款分期': '贷款分期',
    '常见问题': '常见问题',
    '联系我们': '联系我们',
    '金融信贷服务': '金融信贷服务',
    '用钱就找': '用钱就找',
    '速度快': "速度快",
    '额度高': "额度高",
    '操作简单': "操作简单",
    '品牌强': "品牌强",
    '最高可借额度': '最高可借额度',
    '30秒审批，3分钟到账': '30秒审批，3分钟到账',
    '手机即可申请，还款灵活操作': '手机即可申请，还款灵活操作',
    '权威品牌，安全透明': '权威品牌，安全透明',
    '借款步骤': '借款步骤',
    '打开App': '打开App',
    '获得额度': '获得额度',
    '实时到账': '实时到账',
    'APP首页点击“我要申请”': 'APP首页点击“我要申请”',
    '填写信息获取额度点击“我要贷款”': '填写信息获取额度点击“我要贷款"',
    '恭喜！借款成功 到账至银行卡': '恭喜！借款成功 到账至银行卡',
    '申请的门槛高吗？': '申请的门槛高吗？',
    '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请': '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请',
    '借款后可以多久到账？': '借款后可以多久到账？',
    '通过审核后最快三分钟到账': '通过审核后最快三分钟到账，发放到银行卡，但因网络原因，到账时间可能会延迟，具体到账时间请以收款银行卡通知为准',
    '什么时候可以还款?': '什么时候可以还款?',
    '可以进行提前还款': '可以进行提前还款，今天借，明天还，还款只计算截止到当天的利息',
    '时间': '(服务时间:每日9:00至21:00)',
    '如需联系我们请拨打客服热线': '如需联系我们请拨打客服热线',
    '客服': '客服',
    '下载App': '下载App',
    '官方Facebook': '官方Facebook',
    'Ios客户端': 'Ios客户端',
    '安卓客户端': 'Android客户端',
    '推荐与服务': '推荐与服务',
    '服务号': '服务号',
    '官方邮箱': '官方邮箱',
    '地址': '地址',
    '关于我们': '关于我们',
    '官方Line': '官方Line',
    '查看更多问题': '查看更多问题',
    "立即申请": '立即申请',
    "借钱来这!": "借钱来这!",
    "安全": "安全",
    '放心': '放心',
    '保障': '保障',
    "快速审批 灵活借还": "快速审批 灵活借还",
    "我们的优势": "我们的优势",
    "低利息": "低利息",
    "0抵押0担保额度有保障": "0抵押0担保额度有保障",
    "申请流程": "申请流程",
    "官方": "官方",
    "隐私政策": '隐私政策',
    "电话": "电话",
    "邮箱": "邮箱",
    "信用生活": "信用生活",
    "个人消费": "个人消费",
    "最高可贷20000申请便捷": "最高可贷20000申请便捷",
    "最高可贷20000快速审批": "最高可贷20000快速审批",
    "最高可贷20000极速审批": "最高可贷20000极速审批",
    "口腔": '口腔',
    "医美": "医美"
}