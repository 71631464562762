
import { Button, Container, Row, Col, Navbar, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap'
import banner from '../../resources/images/banner.png'
import money from '../../resources/images/money.png'
import brand from '../../resources/images/brand.png'
import speed from '../../resources/images/speed.png'
import easy from '../../resources/images/easy.png'
import call from '../../resources/images/call.png'
import connect from '../../resources/images/connect.png'
import { useTranslation, getI18n } from 'react-i18next'
import Steps from 'rc-steps'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const store: any = useContext(StoreContext)
    const { t } = useTranslation()
    const { language } = getI18n()
    const isEN = language == 'en'
    const formatStepTitle = (title: string) => {
        return (
            <>
                <span className='steps-title'>{t(`${title}`)}</span>
            </>
        )
    }
    const formatStepDescription = (title: string, stepPrc: any) => {
        return (
            <>
                <div className='steps-d' style={{ height: 120 }}>{t(`${title}`)}</div>


                <div style={{ height: 330, backgroundImage: `url(${stepPrc})`, width: '245px' }}>
                </div>

            </>
        )
    }
    useEffect(() => {
        var stepsOne = document.getElementsByClassName('rc-steps-item-process')
        if (stepsOne) {
            stepsOne[0]?.classList.add('rc-steps-item-wait')
            stepsOne[0]?.classList.remove('rc-steps-item-process')
        }
    })
    return (
        <>
            <Container fluid={true} className='p-t-58 px-0'>
                <div id='home'>
                    <img src={banner} width="100%" height="580px" />
                    <div className='image-contail'>
                        <p className='service-title title-color pt-0 mb-0'>
                            {t('金融信贷服务')}
                        </p>
                        <p className={classnames('service-title title-color pt-0', isEN ? 'ft-40' : 'ft-60')}>
                            {t('用钱就找')}{store?.company}
                        </p>
                    </div>
                </div>
                <div id='service' className='serviceContainer justify-content-center align-items-center  d-flex flex-column '>
                    <div className='justify-content-center service-title d-flex'>
                        {t('金融信贷服务')}
                    </div>
                    <div className="serviceContainerWarp d-flex flex-row justify-content-between">
                        <div className="w-250 justify-content-center align-items-center  d-flex flex-column ">
                            <img src={money} width="155px" height="155px" />
                            <p className='ft-30 icon-title'>
                                {t('额度高')}
                            </p>
                            <div className="h30">
                                <p className='icon-text '>
                                    {t('最高可借额度')}  20000
                            </p>
                            </div>
                        </div>
                        <div className="w-250 justify-content-center align-items-center  d-flex flex-column">
                            <img src={speed} width="155px" height="155px" />
                            <p className='ft-30 icon-title'>
                                {t('速度快')}
                            </p>
                            <div className="h30">
                                <p className='icon-text'>
                                    {t('30秒审批，3分钟到账')}
                                </p>
                            </div>
                        </div>
                        <div className="w-250 justify-content-center align-items-center  d-flex flex-column">
                            <img src={brand} width="155px" height="155px" />
                            <p className='ft-30 icon-title'>
                                {t('品牌强')}
                            </p>
                            <div className="h30">
                                <p className='icon-text'>
                                    {t('手机即可申请，还款灵活操作')}
                                </p>
                            </div>
                        </div>

                        <div className="w-250 justify-content-center align-items-center  d-flex flex-column">
                            <img src={brand} width="155px" height="155px" />
                            <p className='ft-30 icon-title'>
                                {t('品牌强')}
                            </p>
                            <div className="h30">
                                <p className='icon-text'>
                                    {t('权威品牌，安全透明')}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div id='step' className='stepContainer d-flex  align-items-center flex-column'>
                    <div>
                        <p className='step-title'>{t('借款步骤')}</p>
                    </div>
                    <div className='steps'>
                        <Steps labelPlacement="vertical">
                            <Steps.Step title={formatStepTitle('打开App')}
                                description={formatStepDescription('APP首页点击“我要申请”', store?.step1)} />
                            <Steps.Step title={formatStepTitle('获得额度')}
                                description={formatStepDescription('填写信息获取额度点击“我要贷款”', store?.step2)} />
                            <Steps.Step title={formatStepTitle('实时到账')}
                                description={formatStepDescription('恭喜！借款成功 到账至银行卡', store?.step3)} />
                        </Steps>
                    </div>
                </div>
                <div id='question' className='questionContailner d-flex  align-items-center flex-column'>
                    <div>
                        <p className='question-title'>{t('常见问题')}</p>
                    </div>
                    <div className='steps'>
                        <div className='mb-40'>
                            <p className='question-t'> 1、{t('申请的门槛高吗？')}</p>
                            <p className='question-a'> {t('提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请')}</p>
                        </div>
                        <div className='mb-40'>
                            <p className='question-t'> 2、{t('借款后可以多久到账？')}</p>
                            < p className='question-a' > {t('通过审核后最快三分钟到账')}</p>
                        </div>
                        <div>
                            <p className='question-t'> 3、{t('什么时候可以还款?')}</p>
                            <p className='question-a'>{t('可以进行提前还款')}</p>
                        </div>

                    </div>
                </div>
                <div id='connect' className='connect d-flex  align-items-center flex-column'>
                    <div className='steps pt-0'>
                        <p className='look-q'>
                            {t('查看更多问题')} &gt;&gt;
                        </p>
                        <hr className='question-h'></hr>
                        <div className='d-flex'>
                            <div className='qusetion-icon  d-flex ' >
                                <div>
                                    <img src={connect} width="105px" height="105px" />
                                </div>
                                <div style={{ marginLeft: '38px' }}>
                                    <p className='quesiton-c'>{t('客服')} line</p>
                                    <p className='line'> {store?.customerServerLine}</p>
                                </div>
                            </div>
                            <div className='qusetion-icon  d-flex ' >
                                <div>
                                    <img src={call} width="105px" height="105px" />
                                </div>
                                <div style={{ marginLeft: '38px' }}>
                                    <p className='quesiton-c'>{t('如需联系我们请拨打客服热线')}</p>
                                    <span>{store?.customerPhone}</span>
                                    <span className='time'>{t('时间')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>)
}

export default BodyContext